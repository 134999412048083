@import '../../components/fx/fx.css';

.portfolio-title {
    margin: 16px 50px 16px !important;
    text-align: start;
  }

.hero {
  animation: fadeIn 1s linear 1;
}

.gh-link:hover {
  color: rgb(135, 207, 255);
}