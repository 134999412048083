.frame-link {
font-size: 10px; 
color: #cccccc;
word-break: normal;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis; 
font-family: 'Interstate, Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif';
font-weight: 100;
}

.main-visual {
display: flex;
justify-content: center;
}

iframe {
width: 640px;
height: 564px;
}

@media screen and (max-width: 769px) {
  iframe {
    width: 320px;
    height: 250px;
  }
}