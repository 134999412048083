body {
  font-family: 'Sora', sans-serif;
}

.link {
  color: black;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.button {
  border-radius: 10px !important;
}

.hero.is-fullheight .hero-body {
  justify-content: center;
}

.is-beautiful {
  background-color: #d3f6ffb5;
}

.is-cool {
  background-color: #fff;
}

.is-chill {
  background-color: #e6e5e5;
}