@keyframes slideDown {
    0% {
      bottom: 30px;
    }
    50% {
      bottom: 10px;
    }
    100% {
      bottom: 0px;
    }
  }


@keyframes slideLeft {
    0% {
      margin-left: 50px;
    }
    50% {
      margin-left: 10px;
    }
    100% {
      margin-left: 0px;
  }
  }

@keyframes slideUp {
    0% {
      margin-top: 50px;
    }
    50% {
      margin-top: 10px;
    }
    100% {
      margin-top: 0px;
  }
  }


@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 50%;
    }
    100% {
      opacity: 100%;
  }
  }