@import '../../components/fx/fx.css';

.wb-link:hover {
  color: rgb(50, 10, 143);
  text-shadow: 1px 1px 43x rgba(128, 128, 128, 0.353);
  font-weight: 600;
}

.pref:hover {
  color: rgb(16, 83, 124);
  font-weight: 600;
}

.home {
  animation: slideLeft linear 1.5s 1, fadeIn linear 1.5s 1;
}
