@import url('https://fonts.googleapis.com/css2?family=Sora:wght@200;400;500&display=swap');

.card-container {
    background-color: white;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 15px;
    transform: scale(1);
    transition: all .3s linear 0s;
}

.card-container:hover {
    transform: scale(1.04);
    cursor: pointer;
    box-shadow: 1px 1px 40px rgba(58, 138, 195, 0.25);
}

.img-container {
    border-radius: 15px;
    min-height: 142px;
    overflow: hidden;
}

.img-container img {
    min-height: 142px;
}

.card-feature-type {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3a69e1;
    font-size: 10px;
    text-align: center;
    font-weight: 800;
    height: 22px;
    width: 77px;
    background-color: #d6e6fc;
    border-radius: 15px;
    margin-top: 54px;
}

.subtitle {
    width: 100%;
    color: #20293A;
    margin: 16px auto 6px;
}

.info {
    border-bottom: .5px solid #6c727f41;
    margin-bottom: 20px;
    height: 180px;
}

.info-p {
    width: 90%;
    color: #6c727fc7;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
}

.website {
    color: #6c727fc7;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
}

.cards-wrapper {
    max-width: 1200px;
  }

  @media screen and (min-width: 1024px) {
    .img-container {
        max-height: 142px;
    }
  }