@import '../../components/fx/fx.css';

.form-body {
  display: flex;
  flex-direction: column;
  align-content: center;
  min-width: 200px;
  margin: 50px;
  margin-bottom: 100px !important;
  animation: fadeIn linear .7s 1;
  border: 1px solid rgba(128, 128, 128, 0.486);
  border-radius: 15px;
  padding: 40px;
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.092);
}

label {
  min-width: 450px;
}

.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ty-msg {
  font-size: 30px;
  font-weight: 800;
  margin: 80px auto 500px;
  animation: fadeIn linear .3s 1;
}