@import '../fx/fx.css';

.social-links {
  display: flex;
  justify-content: center;
}

.fa-icon {
margin: 1px;
font-size: 25px;
color: #232323;
}

.fa-icon:hover {
color: #3d3d3d;
}

.footer {
  animation: 
  slideUp linear 1.5s 1,
  fadeIn linear 1.5s 1;
}