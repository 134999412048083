@import '../fx/fx.css';

.navbar-item img {
 max-height: 42px;
}

.navbar-item {
  padding: .15rem .75rem;
}

a.navbar-item {
  transition: all .2s ease-in;
}

a.navbar-item:hover {
  background-color: rgba(9, 152, 255, 0.593) !important;
  color:white !important;
  border-radius: 5px;
  }

.navbar {
  animation: 
  slideDown linear 1.5s 1,
  fadeIn linear 1.5s 1;
}

@media screen and (max-width: 768px){
.navbar-menu {
  box-shadow: 1px 1px 5px rgba(62, 62, 62, 0.232);
  position: absolute;
  z-index: 999;
  margin-right: 75px;
  margin-top: 6px;
  opacity: .95;
}
.navbar {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}
a.navbar-item:hover {
  background-color: rgba(9, 152, 255, 0.593) !important;
  color: rgb(7, 7, 7);
  border-radius: 0;
  }
}
@media screen and (min-width: 769px){
.navbar-menu {
   display: flex;
   box-shadow: none;
   flex-grow: 1;
   flex-shrink: 0;
   align-items: stretch;    
   padding: 0.5rem 0;
 }
 .navbar-start {
   align-items: stretch;
   display: flex;
   justify-content: flex-start;
   margin-right: auto;
 }
 .navbar-item {
   align-items: center;
   display: flex;
 }
 .navbar-burger {
   display: none;
 }
.navbar-menu.is-active{
   display: flex;
   flex-direction: column;
   text-align: end;
}
};
